/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useMemo } from 'react'
import * as THREE from 'three'
import {
  useGLTF,
  useTexture,
  useCubeTexture,
  useMatcapTexture,
} from '@react-three/drei'
import * as PropTypes from 'prop-types'

export default function Monitor(props) {
  const group = useRef()
  const { nodes } = useGLTF('/models/MonitorUVFlip.glb')
  const normalMat = useMemo(() => {
    return new THREE.MeshNormalMaterial({})
  })

  const tex = useTexture('/textures/mc_redblue.png')

  const screenAlphaTex = useTexture('/materials/blackMetal/blackMetal_metalness.jpg')

  const matCap = useMemo(() => {
    return new THREE.MeshMatcapMaterial({ matcap: tex })
  }, [tex])



  const envMap = useCubeTexture(
    ['px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png'],
    {
      path: '/environments/studio/',
    },
  )

    const plasticRoughness = useTexture('/textures/monitor/plastic_roughness.png')
    const plasticNormal = useTexture('/textures/monitor/plastic_normal.jpg')

    plasticNormal.wrapS = THREE.RepeatWrapping;
    plasticNormal.wrapT = THREE.RepeatWrapping;
    plasticRoughness.wrapS = THREE.RepeatWrapping;
    plasticRoughness.wrapT = THREE.RepeatWrapping;

    const texRepeat = 12;
    const normalSCale = 0.6

    
    plasticNormal.repeat.set(texRepeat,texRepeat,)
    plasticRoughness.repeat.set(texRepeat,texRepeat)

  const mat = useMemo(() => {
    return new THREE.MeshStandardMaterial({
      color: "#eee9d4",
      // emissive: "#FFF",
      // emissiveIntensity: 0.1,
      // roughnessMap: plasticRoughness,
      metalness: 1,
      normalMap: plasticNormal,
      normalScale: new THREE.Vector2(normalSCale,normalSCale),
      envMap: envMap,
      envMapIntensity: 3
    })
  }, [envMap, plasticRoughness,plasticNormal])

  


  const glossMat = useMemo(()=> {
    return new THREE.MeshStandardMaterial({

      transparent: true,
      color: '#FFF',
      opacity: 0.3,
      roughness: 0.12,
      envMap: envMap,
      envMapIntensity: 200,
      

    })
  })



  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene" scale={10}>

        <mesh
          name="Monitor"
          material={mat}
          geometry={nodes.Monitor.geometry}
          scale={[0.19, 0.19, 0.19]}
          castShadow={true}
        >
          <mesh
            name="Button002"
            material={mat}
            geometry={nodes.Button002.geometry}
            position={[1.29, -1.56, 1.99]}
          />
          <mesh
            name="Button001"
            material={mat}
            geometry={nodes.Button001.geometry}
            position={[1.42, -1.56, 1.99]}
          />
          <mesh
            name="Button003"
            material={mat}
            geometry={nodes.Button003.geometry}
            position={[1.16, -1.56, 1.99]}
          />
          <mesh
            name="Button"
            material={mat}
            geometry={nodes.Button.geometry}
            position={[1.68, -1.56, 1.99]}
          />
          <mesh
            name="blackMetalMat"
            material={mat}
            geometry={nodes.Button004.geometry}
            position={[1.55, -1.56, 1.99]}
          />
          <mesh
            name="blackMetalMat"
            // material={mat}
            geometry={nodes.Cube005.geometry}
            position={[1.81, -1.56, 1.99]}
          >
            <meshBasicMaterial color={"#e00c0c"} /> 
          </mesh>
          <mesh
            name="Screen"
            // material={nodes.Screen.material}
            geometry={nodes.Screen.geometry}
            position={[-0.02, 0, 1.81]}
            scale={[1, 1, 1]}
          >
            <meshBasicMaterial map={props.screenTex} />
          </mesh>
          <mesh
            name="ScreenGloss"
            material={glossMat}
            geometry={nodes.Screen.geometry}
            position={[-0.02, 0, 1.83]}
            scale={[1, 1, 1]}
            
          >
         
          </mesh>
          <mesh
            name="Stand"
            material={mat}
            geometry={nodes.Stand.geometry}
            position={[0, -0.65, 0]}
            receiveShadow={true}
          />
        </mesh>
      </group>
    </group>
  )
}

Monitor.propTypes = {
  screenTex: PropTypes.instanceOf(THREE.Texture).isRequired,
}

useGLTF.preload('/models/MonitorUVFlip.glb')
