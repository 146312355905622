import * as React from 'react'
import { memo } from 'react'
import * as THREE from 'three'
import { useFrame, useThree } from '@react-three/fiber'
import useSectionScroll from '../../hooks/useSectionScroll'
import useViewLimits from '../../hooks/useViewLimits'
import useMousePosition from '../../hooks/useMousePosition'
import { useContext, useMemo, useState } from 'react'
import { gsap } from 'gsap'
import { a, useSpring } from '@react-spring/three'
import Skull from '../../models/Skull'
import Head from '../../models/Head'
import { AllSectionsContext } from '../../components/ScrollPageRenderer'

import useAspect from '../../hooks/useAspect'

const ViewSkull = memo((props) => {
  // const section = useContext(SectionContext);
  const zPos = 4

  const vl = useViewLimits(zPos)
  const mouseVals = useMousePosition()

  const [update, setUpdate] = useState(Date.now())

  const easeIn = 'power2.in'
  const easeOut = 'power2.out'

  const [aspectCorrection, setAspectCorrection] = useState(new THREE.Vector3(1, 1, 1))

  const aspect = useAspect()

  React.useEffect(() => {
    const sc = aspect

    if (sc < 1) {
      setAspectCorrection(new THREE.Vector3(sc, sc, sc))
    } else if (aspectCorrection.length() < 1) {
      setAspectCorrection(new THREE.Vector3(1, 1, 1))
    }

    console.log('state updated')
  }, [aspect])

  const skullProps = useSectionScroll(
    {
      y: 1,
      // rotateX: 0 // immediately taken over by mouse
    },
    (data, target) => {
      const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: target,
          start: 'top bottom',
          end: 'bottom top',
          scrub: true,
          // markers: true,
          onEnter: () => {
            // console.log('Skull enter')
          },
          onUpdate: () => {
            setUpdate(Date.now())
          },
          onLeave: () => {
            // console.log('skull Leaver ')
            // console.log(this.progress())
          },
        },
      })

      timeline.to(data, {
        y: 0,
        ease: easeOut,
      })

      timeline.to(data, {
        y: -1,
        // duration: 5,
        ease: easeIn,
      })
    },
  )
  const headProps = useSectionScroll(
    {
      y: 1,
      // rotateX: 0 // immediately taken over by mouse
    },
    (data, target) => {
      const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: target,
          start: 'top bottom',
          end: 'bottom top',
          scrub: true,
          // markers: true,
          onEnter: () => {
            // console.log('Skull enter')
          },
          onUpdate: () => {
            // console.log("Scroll");
          },
          onLeave: () => {
            // console.log('skull Leaver ')
          },
        },
      })

      timeline.to(data, 
        { y: 0, ease: easeOut })

      timeline.to(data, {
        y: -1,
        // duration: 5,
        ease: easeIn,
      })
    },
  )

  const skullSize = 2.5
  const skullScale = 1.5
  const skullDims = (skullSize ) * skullScale
  // Arbitrary 
  const maxOffset = 4
  const skullOffset = Math.max(1- aspect, 0) * -maxOffset
  const headOffset = Math.max(1- aspect, 0) * maxOffset

  // const headOffset = vl.current.x * Math.min(0, 1- aspectCorrection.length())

  const {
    skullPosition,
    skullRotateX,
    skullRotateY,
    headPosition,
    headRotateX,
    headRotateY,
  } = useSpring({
    skullPosition: [
      -0.5 * vl.current.x + skullDims + skullOffset,
      skullProps.current.y * vl.current.y,
      zPos,
    ],
    skullRotateX: mouseVals.y * Math.PI * -0.2,
    skullRotateY: skullProps.current.y * Math.PI * 0.5 + Math.PI * 0.25,
    headPosition: [
      0.5 * vl.current.x - skullDims + headOffset,
      headProps.current.y * vl.current.y * -1,
      zPos,
    ],
    headRotateX: mouseVals.y * Math.PI * 0.2,
    headRotateY: headProps.current.y * Math.PI * -0.5 + -Math.PI * 0.25,
    config: {
      tension: 140,
      mass: 3,
    },
  })

  return (
    <group>
      {/* @ts-ignore */}
      <a.group
        //@ts-ignore
        position={skullPosition}
        rotation-x={skullRotateX}
        rotation-y={skullRotateY}
        scale={1.5}
      >
        <group scale={aspectCorrection}>
          <Skull />
        </group>
      </a.group>
      {/* @ts-ignore */}
      <a.group
        //@ts-ignore
        position={headPosition}
        rotation-x={headRotateX}
        rotation-y={headRotateY}
        scale={1.5}
      >
        <group scale={aspectCorrection}>
          <Head />
        </group>
      </a.group>
    </group>
  )
})

export default ViewSkull
