import * as React from 'react'
import {
  useRef,
  useState,
  useEffect,
  useContext,
  MutableRefObject,
} from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { AllSectionsContext } from '../components/ScrollPageRenderer'
import { IDContext } from '../components/Section/Section'
import { getSectionID } from "../data/getSectionID"
import { useStore } from '../data/state'

function useRelativeSectionScroll<T>(
  template: T,
  sectionOffset: number,
  func: (object: T, target: string | Element) => void,
): MutableRefObject<T> {
  const curSection = useContext(IDContext)
  const targetSectionIndex = curSection.index + sectionOffset
  const targetSectionID = getSectionID(targetSectionIndex)
  const element = useStore((state) => state.domElements.get(targetSectionID))

  const [initialized, setInitialized] = useState(false)

  const ref = useRef(template)

  // const [timeline, setTimeline] = useState<gsap.core.Timeline>(gsap.timeline())
  // Make sure we're grabbing the section correctly
  useEffect(() => {
    // Bail if the section is not ready, or if the gsap function has already been run
    if (initialized) {
      // console.log('Already initialized')
      return
    }
    if (targetSectionIndex < 0 || targetSectionID === '') {
      // console.log(
      //   'No ID for index, bailing',
      //   targetSectionIndex,
      //   targetSectionID,
      // )
      return
    }
    if (!element) {
      console.log('No context, bailing')
      return
    }
    setInitialized(true)

    // Run the GSap function, passing the current ref from the initialized refShape
    func(ref.current, element)
    // console.log('initialized, func called with', ref.current, element)
  }, [targetSectionIndex, targetSectionID, element])

  return ref
}

export { useRelativeSectionScroll }
export default useRelativeSectionScroll
