import TextAnim from './TextAnim'
import { SceneDef } from '../../data/state'

const textDescenderPass: SceneDef = {
  bgColor: 'black',
  html: <TextAnim />,
}
export { TextAnim, textDescenderPass }

export default textDescenderPass
