import { Center } from '@react-three/drei'
import { SceneDef } from '../../data/state'
import ViewSkull from './SkullScene'
import '../../App.scss'

// import s from "./skullsPass.module.scss";

const SkullPassText: React.FC = () => {
  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        scrollSnapAlign: 'start',
      }}
    >
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
        }}
      >
        <div
        className={'shadowBox'}
          style={{
            zIndex: 2,
            pointerEvents: 'auto',
            textAlign: 'center',
          }}
        >
          <h2>I create</h2>
          <h1>Digital Experiences</h1>
          <h2>for humans</h2>
          {/* <p>for humans</p> */}
        </div>
      </div>
    </div>
  )
}

export const SkullsPass: SceneDef = {
  bgColor: 'black',
  html: <SkullPassText> </SkullPassText>,
  three: <ViewSkull />,
}

export default SkullsPass
