import * as React from 'react'
import * as THREE from 'three'

import { useFrame, createPortal, useThree } from '@react-three/fiber'
import { useFBO } from '@react-three/drei'
import { useRef, useMemo, useState } from 'react'

// Efects Composer
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { BloomPass } from 'three/examples/jsm/postprocessing/BloomPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'

// Shader imports
import { PixelShader } from 'three/examples/jsm/shaders/PixelShader.js'
import {
  FilmShader,
  RGBShiftShader,
  BadTVShader,
  StaticShader,
} from '../../shaders/badTV'
import { useStore } from '../../data/state'

// Monitor
import Monitor from '../../models/Monitor'
import useSectionScroll from '../../hooks/useSectionScroll'
import ScreenScene from './ScreenScene'

import { gsap } from 'gsap'
import { useSpring, a } from '@react-spring/three'
import * as ease from 'd3-ease'
import useMousePosition from '../../hooks/useMousePosition'
import useRelativeSection from '../../hooks/useRelativeSection'
import { disableScroll, enableScroll } from './disableScroll'
import useRelativeSectionScroll from '../../hooks/useRelativeSectionScroll'

import ScrollToAdvance from './ScrollToAdvance'


function FBOScene() {
  /**
   * RENDER MAGIC
   */

  // Temporarily disable scrolling
  // React.useEffect(()=> {
  //     disableScroll();
  //     setTimeout(enableScroll, 2000)
  // },[])

  const targetContext = useFBO(800, 600)

  const { gl } = useThree()

  // Creating the camera manuall here to force the rendered scene to the right aspect ratio
  const fboCam = React.useMemo(() => {
    const cam = new THREE.PerspectiveCamera()
    cam.aspect = 1.33
    cam.position.set(0, 0, 10)
    return cam
  }, [])

  const fboScene = React.useMemo(() => {
    const scene = new THREE.Scene()
    scene.add(fboCam)
    scene.background = new THREE.Color('#000')
    // scene.background = new THREE.Color('#80968d')
    return scene
  }, [fboCam])

  const renderPass = useMemo(() => {
    return new RenderPass(fboScene, fboCam)
  }, [])

  const pixelPass = useMemo(() => {
    const pixelPass = new ShaderPass(PixelShader)
    pixelPass.uniforms['resolution'].value = new THREE.Vector2(100, 100)
    // pixelPass.uniforms[ "resolution" ].value.multiplyScalar( window.devicePixelRatio );
    return pixelPass
  }, [])

  const badTVPass = useMemo(() => {
    const pass = new ShaderPass(BadTVShader)
    // Uniforms
    pass.uniforms['distortion'].value = 1.8
    pass.uniforms['distortion2'].value = 1
    pass.uniforms['speed'].value = 0.15
    pass.uniforms['rollSpeed'].value = 0
    return pass
  }, [])

  const filmPass = useMemo(() => {
    const pass = new ShaderPass(FilmShader)
    // Uniforms
    pass.uniforms['grayscale'].value = 0
    pass.uniforms['sCount'].value = 140
    pass.uniforms['sIntensity'].value = 0.9
    pass.uniforms['nIntensity'].value = 0.1

    return pass
  }, [])
  const rgbShiftPass = useMemo(() => {
    const pass = new ShaderPass(RGBShiftShader)
    // Uniforms
    pass.uniforms['angle'].value = 0.4
    pass.uniforms['amount'].value = 0.005
    return pass
  }, [])
  const staticPass = useMemo(() => {
    const pass = new ShaderPass(StaticShader)
    pass.uniforms['amount'].value = 0.12
    pass.uniforms['size'].value = 1.2
    // Uniforms
    return pass
  }, [])

  const bloomPass = useMemo(() => {
    return new BloomPass()
  }, [])
  const unrealBloomPass = useMemo(() => {
    return new UnrealBloomPass(
      new THREE.Vector2(window.innerWidth, window.innerHeight),
      0.8,
      0.6,
      0.7,
    )
    // bloomPass.threshold = 1.5
  }, [])

  const composer = useMemo(() => {
    const res = new EffectComposer(gl, targetContext)
    res.renderToScreen = false
    res.passes = [
      renderPass,
      pixelPass,
      rgbShiftPass,
      unrealBloomPass,
      // bloomPass,
      filmPass,
      badTVPass,
      staticPass,
      // glitchPass,
    ]

    return res
  }, [])

  const shaderTime = useRef(0)

  useFrame((state, delta) => {
    shaderTime.current += delta
    state.gl.setRenderTarget(targetContext)
    badTVPass.uniforms['time'].value = shaderTime.current
    filmPass.uniforms['time'].value = shaderTime.current
    staticPass.uniforms['time'].value = shaderTime.current

    pixelPass.uniforms['resolution'].value = new THREE.Vector2(
      pixelResData.current.PIXEL_RES,
      pixelResData.current.PIXEL_RES,
    )

    composer.render(delta)
    composer.swapBuffers()
    composer.render()
    composer.swapBuffers()
    state.gl.setRenderTarget(null)
  })

  /**
   * Animation magic!
   */

  const mouseVals = useMousePosition()

  const [update, setUpdate] = useState(Date.now())

  const endTrigger = useRelativeSection(2)

  const pixelResData = useRelativeSectionScroll(
    {
      PIXEL_RES: 100,
    },
    1,
    (data, target) => {
      const tl = gsap
        .timeline({
          scrollTrigger: {
            trigger: target,
            start: 'top bottom',
            end: '+=100%',
            scrub: true,
            onUpdate() {
              setUpdate(Date.now())
            },
          },
        })
        .to(data, {
          PIXEL_RES: 400,
          ease: 'power2.easeInOut',
        })
    },
  )

  const powEase = ease.easeExpIn(3)
  const MONITOR_START_Z = 12.5

  const monitorZoomOutData = useRelativeSectionScroll(
    {
      zPosition: MONITOR_START_Z,
      mouseLock: 1,
    },
    2,
    (data, target) => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: target,
          scrub: true,
          start: 'top bottom',
          end: 'bottom 50%',
          onUpdate() {
            setUpdate(Date.now())
          },
        },
      })

      tl.to(data, {
        zPosition: 5,
        mouseLock: 0,
        ease: 'Power3.easeInOut',
      })
    },
  )
  // This should cover 16:9 displays
  // Anyone viewing at a wider aspect ratio is a sociopath
  const { zPosition, mouseLock } = useSpring({
    to: {
      zPosition: monitorZoomOutData.current.zPosition,
      mouseLock: monitorZoomOutData.current.mouseLock,
    },
    config: {},
  })

  // Rotation spring
  const { xRotation, yRotation } = useSpring({
    xRotation: mouseVals.x * 0.5 * (1 - monitorZoomOutData.current.mouseLock),
    yRotation:
      mouseVals.y *
      Math.PI *
      -0.25 *
      0.5 *
      (1 - monitorZoomOutData.current.mouseLock),
  })

  const monitorExitData = useRelativeSectionScroll(
    {
      exitPositionX: 0,
      exitPositionY: 0,
      exitPositionZ: 0,
      exitRotationX: 0,
      exitRotationY: 0,
    },
    4,
    (data, target) => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: target,
          start: 'top bottom',
          end: 'bottom top',
          scrub: true,
          onUpdate() {
            setUpdate(Date.now())
          },
        },
      })
      tl.to(data, {
        exitPositionX: 0,
        exitPositionY: 0,
        exitPositionZ: 5,
        exitRotationX: Math.PI * -0.1,
        exitRotationY: 0,
        duration: 0.2,
        ease: 'Power2.easeInOut',
      })
      tl.to(data, {
        exitPositionX: 0,
        exitPositionY: 0,
        exitPositionZ: -200,
        exitRotationX: Math.PI * 2,
        exitRotationY: Math.PI * 0.4,
        duration: 1,
        ease: 'Power2.easeIn',
      })
    },
  )

  const { exitPosition, exitRotationX, exitRotationY } = useSpring({
    exitPosition: [
      monitorExitData.current.exitPositionX,
      monitorExitData.current.exitPositionY,
      monitorExitData.current.exitPositionZ,
    ],
    exitRotationX: monitorExitData.current.exitRotationX,
    exitRotationY: monitorExitData.current.exitRotationY,
  })

  return (
    <React.Fragment>
      <ScrollToAdvance/>

      {createPortal(<ScreenScene />, fboScene)}
      {/* @ts-ignore */}
      <pointLight
        position={[4, 7, 12]}
        intensity={0.2}
        color={'#f5f1ea'}
        castShadow
      />
      <pointLight
        position={[-4, -3, 20]}
        intensity={0.22}
        color={'#6e6e94'}
        castShadow
      />

      <a.group
        // @ts-ignore
        position-z={zPosition}
      >
        <a.group rotation-y={xRotation} rotation-x={yRotation}>
          <a.group
            // @ts-ignore
            position={exitPosition}
            rotation-x={exitRotationX}
            rotation-y={exitRotationY}
          >
            {/* @ts-ignore */}
            <Monitor screenTex={targetContext.texture} />
          </a.group>
        </a.group>
      </a.group>
    </React.Fragment>
  )
}

export default FBOScene
