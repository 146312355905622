import { useEffect, useState } from 'react'

export default function useMousePosition() {
  // const {viewPort} = useThree()
  //   const [mouseCoords, setMouseCoords] = useState({ x: 0, y: 0 });
  const [normalizedMouseCoords, setNormalizedMouseCoords] = useState({
    x: 0,
    y: 0,
  })

  function onMouseMove(event: MouseEvent) {
    // console.log("setting mouse coords", event.clientX, event.clientY);

    // setMouseCoords({
    //   x: event.clientX,
    //   y: event.clientY,
    // });
    setNormalizedMouseCoords({
      x: (event.clientX / window.innerWidth - 0.5) * 2,
      y: (event.clientY / window.innerHeight - 0.5) * -2,
    })
  }

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove)
    // window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener('mousemove', onMouseMove)
      //   window.removeEventListener("mousemove", onResize);
    }
  }, [])

  return normalizedMouseCoords
}
