import * as React from 'react'

import ScrollPageRenderer from '../components/ScrollPageRenderer'

import Head from '../models/Head'
import monitorPass from '../scenes/MonitorScene/monitorPass'
import skullPass from '../scenes/SkullScene/skullPass'
import '../App.scss'
import useAspect from '../hooks/useAspect'



function App() {


  return (
    <div className={'App'}>

      <div className="webgl">
        {/* <MainScene /> */}
        <ScrollPageRenderer></ScrollPageRenderer>
      </div>
    </div>
  )
}

export default App
