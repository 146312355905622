import * as React from 'react'
import {
  useRef,
  useState,
  useEffect,
  useContext,
  MutableRefObject,
} from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { AllSectionsContext } from '../components/ScrollPageRenderer'
import { IDContext } from '../components/Section/Section'
import { useStore } from '../data/state'

function useSectionScroll<T>(
  template: T,
  func: (object: T, target: string | Element) => void,
): MutableRefObject<T> {
  const section = useContext(IDContext)

  const element = useStore((state) => state.domElements.get(section.id))

  const [initialized, setInitialized] = useState(false)

  const ref = useRef(template)

  // const [timeline, setTimeline] = useState<gsap.core.Timeline>(gsap.timeline())
  // Make sure we're grabbing the section correctly
  useEffect(() => {
    // Bail if the section is not ready, or if the gsap function has already been run
    if (initialized) {
      console.log('Already initialized')
      return
    }
    if (section.index < 0 || section.id === '') {
      console.log('No ID, bailing', section)
      return
    }
    if (!element) {
      console.log('No context, bailing')
      return
    }
    setInitialized(true)

    // Run the GSap function, passing the current ref from the initialized refShape
    func(ref.current, element)
    // console.log('initialized, func called with', ref.current, element)
  }, [section, element])

  return ref
}

export { useSectionScroll }
export default useSectionScroll
