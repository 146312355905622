import { string } from 'prop-types'
import React, { useRef, useEffect, useState, useMemo } from 'react'
import { useStore } from '../../data/state'
import { getSectionID } from '../../data/getSectionID'
import s from './Section.module.scss'
import ScrollSnap from 'scroll-snap'

interface SectionProps {
  index: number
  color?: string
  length?: number
}

function generateCSSHeight(viewLength: number | null | undefined) {
  const length = viewLength ? viewLength : 1
  return `max(500px, ${length * 100}vh)`
  // return `100vh`;
}

const SectionLengthContext = React.createContext(1)

interface SectionID {
  id: string
  index: number
}

export const IDContext = React.createContext<SectionID>({
  id: '',
  index: -1,
})

export const DOMSection: React.FC<SectionProps> = (props) => {
  const ref = useRef<HTMLDivElement>(null)

  const assignElements = useStore((state) => state.assignElements)

  const [sectionID, setSectionID] = useState<string>('')

  useEffect(() => {
    if (ref.current) {
      const curSectionID = getSectionID(props.index)
      setSectionID(curSectionID)
      assignElements(curSectionID, ref.current)
    }
  }, [props.index])

  // const height = useMemo(() => {
  //   return generateCSSHeight(props.length)
  // }, [props.length])



  return (
    <div
      ref={ref}
      id={sectionID}
      className={s.section}
      style={{
        // minHeight: height,
        // maxHeight: height,
        background: props.color || undefined,
      }}
    >
      <IDContext.Provider
        value={{
          id: sectionID,
          index: props.index,
        }}
      >
        <SectionLengthContext.Provider value={props.length || 1}>
          {props.children}
        </SectionLengthContext.Provider>
      </IDContext.Provider>
    </div>
  )
}
export const THREESection: React.FC<SectionProps> = (props) => {
  const sectionID = getSectionID(props.index)

  return (
    <IDContext.Provider
      value={{
        id: sectionID,
        index: props.index,
      }}
    >
      {props.children}
    </IDContext.Provider>
  )
}
