import create from 'zustand'
import skullPass from '../scenes/SkullScene/skullPass'
import monitorPass from '../scenes/MonitorScene/monitorPass'
import sayHiPass from '../scenes/SayHiScene'
import { textDescenderPass } from '../scenes/TextDescender'
import { cloneDeep } from 'lodash'
import {getSectionID } from './getSectionID'

export {getSectionID}

type State = {
  scenes: SceneDef[]
  domElements: Map<string, Element>
  assignElements(id: string, element: Element): void
}

export interface SceneDef {
  html?: React.ReactNode // Contents to display inside the view
  three?: React.ReactNode // The three components to sync
  bgColor?: string
}

const black : SceneDef = {
  bgColor: "#000"
}

const white : SceneDef = {
  bgColor: "#FFF"
}

const placeHolder = (color: string) => {
  const res : SceneDef = {
    bgColor: color
  }

  return res
}

export const useStore = create<State>((set) => ({
  scenes: [
    monitorPass,
    black,
    black,
    textDescenderPass,
    black, 
    skullPass,
    // black, 
    sayHiPass
  ],
  domElements: new Map<string, Element>(),
  assignElements: (id, element) =>
    set((state) => {
      const curElements = cloneDeep(state.domElements)
      curElements.set(id, element)
      return {
        domElements: curElements,
      }
    }),
}))
