import React from 'react'
import { gsap } from 'gsap'
import useGsap from '../../hooks/useGsap'

import s from '../../styles/scenes.module.scss'
import '../../App.scss'
import { relative } from 'node:path'
import { RGBA_ASTC_10x10_Format } from 'three'

const TextDescender: React.FC = (props) => {
  const { triggerRef, targetRef, timeline } = useGsap((tl, trigger, target) => {
    tl = gsap.timeline({
      scrollTrigger: {
        trigger: trigger,
        // start: 'top center',
        // end: '+=500',
        // toggleActions: 'play none none reverse',
        // pin: target
      },
    })

    // tl.from(target, {
    //   // yPercent: 150,
    //   duration: 0.8,
    //   ease: 'power3.easeOut',
    // }).to(target, {
    //   // yPercent: 0,
    // })
  })

  return (
    <div className={s.fillParent} ref={triggerRef} >
      <div
        style={{
          position: 'relative',
          zIndex: 2,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: "white",

          textAlign: 'center',
        }}
      >
        <div
          className={'shadowBox'}
        ref={targetRef} 
        style={{

        }} >

          <h2>I'm a</h2>
          <h1>Creative Technologist</h1>
          <h2>and</h2>
          <h1>Animator</h1>

          {/* <h1
            style={{
              // paddingTop: '100px',
              fontSize: '100px',
            }}
          >
            A Creative Technologist <br/> And Animator
          </h1> */}
        </div>
      </div>
    </div>
  )
}

export default TextDescender
