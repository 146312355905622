import * as React from 'react'
import {useState, useEffect} from 'react'
import { Html } from '@react-three/drei'
import classnames from 'classnames'
import s from './ScrollToAdvance.module.scss'

const ScrollToAdvance: React.FC = (props) => {

    // Is the popup currently active
    const [active, setActive] = useState(false)
    // Has the popup ever been active? (Prevents triggereing again)
    const [triggered, setTriggered] = useState(false)

    useEffect(()=> {
        // Halt everything if scrolling begins 
        function hasTriggered(){
            setActive(false)
            setTriggered(true)
        }

        function trigger(){
            // Only fire if this has not fired before 
            if(!triggered) {
                setActive(true)
                setTriggered(true)



            }
        }

        document.addEventListener('scroll', hasTriggered)

        setTimeout(trigger, 4000)


        return ()=> {
            document.removeEventListener('scroll', hasTriggered)
        }


    },[])



  return (
    <React.Fragment>
      <Html fullscreen={true}>
        <div className={s.container}>
            <div
             className={classnames(
                 s.scrollToAdvance,
                 active ? s.active : null,
                 !active && triggered ? s.deactivated : null
             )}
            ><h1 className={s.scrollHeader}>Scroll to advance</h1></div>
        </div>
      </Html>
    </React.Fragment>
  )
}

export default ScrollToAdvance