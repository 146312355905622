import { fromPairs } from 'lodash'
import React, { useEffect } from 'react'
import { useRef, useMemo } from 'react'
import * as THREE from 'three'
import useSectionScroll from '../../hooks/useSectionScroll'
import { SceneDef } from '../../data/state'
import MonitorScene from './MonitorScene'
import { gsap } from 'gsap'
import useGsap from '../../hooks/useGsap'
import s from '../../styles/scenes.module.scss'

const MonitorPassText: React.FC = () => {

  console.log('rendering monitor text')
  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        scrollSnapAlign: 'start',
      }}
    >
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
        }}
      >
        <div
          style={{
            zIndex: 2,
            pointerEvents: 'auto',
            textAlign: 'center',
          }}
        >
          {/* <p>Scroll to advance</p> */}
        </div>
      </div>
    </div>
  )
}

const monitorPass: SceneDef = {
  bgColor: 'black',
  html: <MonitorPassText />,
  three: <MonitorScene />,
}

export default monitorPass
