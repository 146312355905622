import SayHiMarkup from  './SayHiMarkup'
import SayHiScene from './SayHiScene'

import {SceneDef} from '../../data/state'

const SayHiPass : SceneDef = {
 three: <SayHiScene />,
 html: <SayHiMarkup/>,
 bgColor: 'black'
}

export {SayHiMarkup,SayHiScene, SayHiPass}

export default SayHiPass