import * as THREE from 'three'
import React, { useRef, useMemo, useEffect, useState } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { a, useSpring } from '@react-spring/three'
import { useStore, getSectionID } from '../data/state'
import { gsap } from 'gsap'

export default function Particles({ count, mouse }) {
  const mesh = useRef()
  const light = useRef()
  const { size, viewport } = useThree()
  const aspect = size.width / viewport.width

  const SYSTEM_SCALE = 2

  const dummy = useMemo(() => new THREE.Object3D(), [])
  // Generate some random positions, speed factors and timings
  const particles = useMemo(() => {
    const temp = []
    for (let i = 0; i < count; i++) {
      const t = Math.random() * 100
      const factor = 20 + Math.random() * 100
      const speed = (0.01 + Math.random() / 200) * 0.2
      const xFactor = -50 + Math.random() * 100
      const yFactor = -50 + Math.random() * 100
      const zFactor = -50 + Math.random() * 100
      temp.push({ t, factor, speed, xFactor, yFactor, zFactor, mx: 0, my: 0 })
    }
    return temp
  }, [count])
  // The innards of this hook will run every frame
  useFrame((state) => {


    // Makes the light follow the mouse
    // light.current.position.set(
    //   mouse.current[0] / aspect,
    //   -mouse.current[1] / aspect,
    //   0,
    // )
    // Run through the randomized data to calculate some movement
    particles.forEach((particle, i) => {
      let { t, factor, speed, xFactor, yFactor, zFactor } = particle
      // Scale speed against he distance of the mouse from center  
      t = particle.t += ((speed / 2) * Math.max(Math.max(Math.abs(mouse.current[0]), Math.abs(mouse.current[1])), 0.2 ))
      // if(i ===0) {console.log(t, mouse.current[0])}
      // There is no sense or reason to any of this, just messing around with trigonometric function
      const a = Math.cos(t) + Math.sin(t * 1) / 10
      const b = Math.sin(t) + Math.cos(t * 2) / 10
      const s = Math.cos(t)
      particle.mx += (mouse.current[0] - particle.mx) * 0.01
      particle.my += (mouse.current[1] * -1 - particle.my) * 0.01
      // Update the dummy object
      dummy.position.set(
        ((particle.mx / 10) * a +
          xFactor +
          Math.cos((t / 10) * factor) +
          (Math.sin(t * 1) * factor) / 10) * SYSTEM_SCALE,
        ((particle.my / 10) * b +
          yFactor +
          Math.sin((t / 10) * factor) +
          (Math.cos(t * 2) * factor) / 10) * SYSTEM_SCALE,
        ((particle.my / 10) * b +
          zFactor +
          Math.cos((t / 10) * factor) +
          (Math.sin(t * 3) * factor) / 10) * SYSTEM_SCALE,
      )
      dummy.scale.set(s, s, s)
      dummy.rotation.set(s * 5, s * 5, s * 5)
      dummy.updateMatrix()
      // And apply the matrix to the instanced item
      mesh.current.setMatrixAt(i, dummy.matrix)
    })
    mesh.current.instanceMatrix.needsUpdate = true
  })

  const numSections = useStore((state) => state.scenes.length)

  const firstStateID = getSectionID(0)
  const lastStateID = getSectionID(numSections - 1)

  const firstState = useStore((state) => state.domElements.get(firstStateID))
  const lastState = useStore((state) => state.domElements.get(lastStateID))

  const tl = useRef(gsap.timeline())

  const progress = useRef({
    value: 0,
  })

  const [_, updateState] = useState(Date.now())
  const [initialized, setInitialized] = useState(false)

  const interpolateAllStates = useEffect(() => {
    if (initialized){ return}
    tl.current.kill()
    tl.current = gsap
      .timeline({
        scrollTrigger: {
          trigger: firstState,
          endTrigger: lastState,
          start: 'top bottom',
          end: 'bottom bottom',
          scrub: true,
          // markers: true
        },
        onUpdate() {
          updateState(Date.now())
          // console.log('"updated prog', progress.current.value);
        },
      })
      .from(progress.current, {
        value: 0,
      })
      .to(progress.current, {
        value: 1,
      })

    setInitialized(true)
  }, [firstState, lastState])

  const { yPosition } = useSpring({
    yPosition: progress.current.value * 20,
    config:{ mass: 5, tension: 20}
  })

  return (
    <a.group position-y={yPosition}>
      <group position={[0, 0, -100]}>
        {/* <pointLight ref={light} distance={40} intensity={0} color="lightblue" /> */}
        <instancedMesh ref={mesh} args={[null, null, count]}>
          <dodecahedronBufferGeometry args={[0.2, 0]} />
          <meshPhongMaterial
            color={"#FFF"}
            // color="#050505"
          />
        </instancedMesh>
      </group>
    </a.group>
  )
}
