import * as React from 'react'

const SayHiScene : React.FC = (props) => {


    return <group>

    </group>

}

export default SayHiScene