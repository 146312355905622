import React, { useState, useEffect, useRef } from 'react'
import {debounce} from 'lodash'

export default function useAspect() {
  const viewRef = useRef(window.innerWidth / window.innerHeight)

  const [aspect, setAspect] = useState(1)

  useEffect(() => {

    setAspect(window.innerWidth / window.innerHeight)

    function onResize() {
      viewRef.current = window.innerWidth / window.innerHeight
      setAspect(window.innerWidth / window.innerHeight)
    }

    const debouncedOnResize = debounce(onResize, 30)

    window.addEventListener('resize', debouncedOnResize)
    return () => {
      window.removeEventListener('resize', debouncedOnResize)
    }
  }, [])
  //return limits
  return aspect
}
