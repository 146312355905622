import React, { useState, useEffect, useRef } from 'react'
import * as THREE from 'three'
import { useThree } from '@react-three/fiber'

const visibleHeightAtZDepth = (
  depth: number,
  camera: THREE.PerspectiveCamera,
) => {
  // compensate for cameras not positioned at z=0
  const cameraOffset = camera.position.z
  if (depth < cameraOffset) depth -= cameraOffset
  else depth += cameraOffset

  // vertical fov in radians
  const vFOV = (camera.fov * Math.PI) / 180

  // Math.abs to ensure the result is always positive
  return 2 * Math.tan(vFOV / 2) * Math.abs(depth)
}

const visibleWidthAtZDepth = (
  depth: number,
  camera: THREE.PerspectiveCamera,
) => {
  const height = visibleHeightAtZDepth(depth, camera)
  return height * camera.aspect
}

export default function useViewLimits(objectDepth: number) {
  const { camera } = useThree()

  const pCam = camera as THREE.PerspectiveCamera

  const viewRef = useRef({
    x: visibleWidthAtZDepth(objectDepth || 0, pCam),
    y: visibleHeightAtZDepth(objectDepth || 0, pCam),
  })

  // TODO: Determine if this should be handled in state or a ref

  const [limits, setLimits] = useState({
    x: visibleWidthAtZDepth(objectDepth || 0, pCam),
    y: visibleHeightAtZDepth(objectDepth || 0, pCam),
  })

  // let widthLimit = useRef(visibleWidthAtZDepth(objectDepth || 0, pCam))
  // let heightLimit = useRef(visibleHeightAtZDepth(objectDepth || 0, pCam))

  // const [widthLimit, setWidthLimit] = useState(
  //   visibleWidthAtZDepth(objectDepth || 0, pCam)
  // );
  // const [heightLimit, setHeightLimit] = useState(
  //   visibleHeightAtZDepth(objectDepth || 0, pCam)
  // );

  useEffect(() => {
    function onResize() {
      // widthLimit = visibleWidthAtZDepth(objectDepth || 0, pCam
      // heightLimit = visibleHeightAtZDepth(objectDepth || 0, pCam

      viewRef.current = {
        x: visibleWidthAtZDepth(objectDepth || 0, pCam),
        y: visibleHeightAtZDepth(objectDepth || 0, pCam),
      }

      setLimits({
        x: visibleWidthAtZDepth(objectDepth || 0, pCam) * 0.5,
        y: visibleHeightAtZDepth(objectDepth || 0, pCam) * 0.5,
      })
    }

    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])
  //return limits
  return viewRef
}
