import { gsap } from 'gsap'
import { useEffect, useRef, useState, useMemo } from 'react'

type TriggerFunction = (
  tl: GSAPTimeline,
  trigger: Element,
  target: Element,
) => void

function useGsap(triggerFunction: TriggerFunction) {
  const triggerRef = useRef<HTMLDivElement>(null)
  const targetRef = useRef<HTMLDivElement>(null)
  const [initialized, setInitialized] = useState(false)

  const tl = useRef(gsap.timeline())

  useEffect(() => {
    if (initialized) {
      return
    }
    if (!triggerRef.current || !targetRef.current) {
      return
    }
    console.log(
      'Running trigger',
      tl.current,
      triggerRef.current,
      targetRef.current,
    )
    try {
      tl.current.kill()
    } catch (e) {
      console.warn(e)
    }

    triggerFunction(tl.current, triggerRef.current, targetRef.current)
    setInitialized(true)
  }, [triggerRef.current, targetRef.current])

  return {
    timeline: tl.current,
    triggerRef,
    targetRef,
  }
}

export default useGsap
