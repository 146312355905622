import React, { useRef, useState, useMemo, useEffect } from 'react'
import useSectionScroll from '../../hooks/useSectionScroll'
import useRelativeSectionScroll from '../../hooks/useRelativeSectionScroll'
import { TextGeometryProps, useFrame } from '@react-three/fiber'
// import Text from '../../components/Text'
import { useSpring, a } from '@react-spring/three'
import { Html } from '@react-three/drei'
import { easeCubicOut, easeCubic } from 'd3-ease'
import * as ease from 'd3-ease'
import * as THREE from 'three'
import Hi from '../../models/Hi'
import { gsap } from 'gsap'
import Skull from '../../models/Skull'
import Dylan from '../../models/Dylan'
import Tricolor from './TriColor'
import Roboto from './Roboto_Bold.json'
import useAspect from '../../hooks/useAspect'
import Background from './Background'

interface TextProps {
  text: string
  color?: string | THREE.Color
}

const Text: React.FC<TextProps> = (props) => {
  const meshRef = useRef()

  const [offset, setOffset] = useState({ x: 0, y: 0 })

  useEffect(() => {
    // @ts-ignore
    const box = new THREE.Box3().setFromObject(meshRef.current)
    const xOffset = (box.max.x - box.min.x) * -0.5
    const yOffset = (box.max.y - box.min.y) * -0.5
    setOffset({
      x: xOffset,
      y: yOffset,
    })
  }, [])

  const font = useMemo(() => {
    return new THREE.FontLoader().parse(Roboto)
  }, [])

  const textOptions = {
    font: font,
    size: 2,
    height: 1,
  }

  return (
    <mesh ref={meshRef} position={[offset.x, offset.y, 0]}>
      <textGeometry attach="geometry" args={[props.text, textOptions]} />
      <meshBasicMaterial color={props.color || 'black'} />
    </mesh>
  )
}

const ScreenScene: React.FC = (props) => {
  const meshRef = useRef<THREE.Mesh>(null!)
  const aspect = useAspect()

  // const id = useContext(IDContext);
  // const forceUpdate = useForceUpdate;
  const [_, setUpdate] = useState(Date.now())

  const time = useRef(0)

  useFrame((frame, delta) => {
    if (meshRef.current) {
      time.current += delta
      //   meshRef.current.rotateX(Math.PI * 0.002)
      //   meshRef.current.rotateY(Math.PI * 0.003)
      //   meshRef.current.scale.set(sc, sc, sc)
    }
  })

  const { turnOnMonitor } = useSpring({
    from: {
      turnOnMonitor: [0, 0, 0],
    },
    to: {
      turnOnMonitor: [1, 1, 1],
    },
    delay: 500,
    config: { duration: 800, easing: easeCubic },
  })

  const firstScaleData = useRelativeSectionScroll(
    {
      scale: 1,
    },
    1,
    (data, target) => {
      const tl = gsap
        .timeline({
          scrollTrigger: {
            trigger: target,
            start: 'top bottom',
            end: '+=100%',
            scrub: true,
            onUpdate() {
              setUpdate(Date.now())
            },
          },
        })
        .to(data, {
          scale: 0,
          ease: 'power2.easeInOut',
        })
    },
  )

  const { textScale, firstScrollScale } = useSpring({
    textScale: [
      firstScaleData.current.scale,
      firstScaleData.current.scale,
      firstScaleData.current.scale,
    ],
    firstScrollScale: [
      1 - firstScaleData.current.scale,
      1 - firstScaleData.current.scale,
      1 - firstScaleData.current.scale,
    ],
  })

  const secondSectionData = useRelativeSectionScroll(
    {
      tricolorScale: 0,
      dylanZ: 0,
      dylanZScale: 1
    },
    3,
    (data, target) => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: target,
          start: 'top bottom',
          end: 'bottom top',
          scrub: true,
          onUpdate() {
            setUpdate(Date.now())
          },
        },
      })

      tl.to(data, {
        tricolorScale: 1,
        dylanZ: 20,
        dylanZScale: 100
      })
    },
  )

  const { triColorScale, dylanZPos, dylanZScale } = useSpring({
    triColorScale: [
      secondSectionData.current.tricolorScale,
      secondSectionData.current.tricolorScale,
      secondSectionData.current.tricolorScale,
    ],
    dylanZPos: secondSectionData.current.dylanZ,
    dylanZScale: secondSectionData.current.dylanZScale,
  })

  //   const BG_COLOR = "#80968d"
  const BG_COLOR = '#aae2ca'

  return (
    // <Effects >
    <React.Fragment>
      <a.group
        position={[0, 0, 0]}
        //@ts-ignore
        scale={textScale}
      >
        <group scale={[Math.min(aspect, 1),Math.min(aspect, 1),Math.min(aspect, 1)]}>
          <Text color="#000000" text={'Hi.'} />
        </group>
      </a.group>
      <a.group
        // @ts-ignore
        scale={turnOnMonitor}
      >
        <mesh position={[-0.15, 0, -20]}>
          <meshBasicMaterial color={BG_COLOR} />
          <planeGeometry args={[26, 23, 1]} />
        </mesh>
      </a.group>

      <a.group
        //@ts-ignore
        scale={triColorScale}
      >
        {/* <Text text={"BG"} color={"red"} /> */}
        {/* <Tricolor /> */}
        <group scale={[0.15, 0.15, 0.15]}>
          <Background />
        </group>
      </a.group>
    
      <a.group
        // @ts-ignore
        scale={firstScrollScale}
      >
        <a.group
          position-z={dylanZPos}
          // scale-z={dylanZScale}
          scale={[
            Math.min(aspect, 1),
            Math.min(aspect, 1),
            Math.min(aspect, 1),
          ]}
        >
          <group scale={[0.32, 0.32, 0.32]} position-z={3}>
            <group position-y={1.2}>
              <Text color="white" text={"I'M"} />
            </group>
            <group position-y={-1.2}>
              <Text color="white" text={'DYLAN'} />
            </group>
          </group>
        </a.group>
        <mesh position={[0, 0, -14]}>
          <meshBasicMaterial color="#000" />
          <planeGeometry args={[30, 20]} />
        </mesh>
      </a.group>
    </React.Fragment>
    // </Effects>
  )
}

export default ScreenScene
