/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, { useRef, useMemo } from 'react'
import { useGLTF, useTexture, useCubeTexture } from '@react-three/drei'

import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'

type GLTFResult = GLTF & {
  nodes: {
    head001: THREE.Mesh
    EyeMask: THREE.Mesh
  }
  materials: {}
}

export default function Model(props: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>()
  const { nodes, materials } = useGLTF('/models/HeadNoEyes.glb') as GLTFResult

  const tex = useTexture('/textures/mc_3.png')
  const matCap = useMemo(() => {
    return new THREE.MeshMatcapMaterial({ matcap: tex })
  }, [tex])

  const envMap = useCubeTexture(
    ['px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png'],
    {
      path: '/environments/studio/',
    },
  )

  const mat = useMemo(() => {
    return new THREE.MeshStandardMaterial({
      envMap: envMap,
      envMapIntensity: 5,
      color: '#525252',
      roughness: 0.12,
      metalness: 1,
    })
  }, [envMap])

  const transparentMat = useMemo(() => {
    // const img = new Image(1,1);

    return new THREE.MeshBasicMaterial({
      transparent: true,
      // opacity: 0.0,
      color: 'white',
      // depthWrite: false,
      alphaTest: 0.2,
      depthTest: true,
    })
  }, [])

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="head001"
          material={mat}
          geometry={nodes.head001.geometry}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <mesh
            name="EyeMask"
            material={transparentMat}
            geometry={nodes.EyeMask.geometry}
            position={[-0.87, 1.54, -0.01]}
            scale={[0.41, 0.41, 0.41]}
          />
        </mesh>
      </group>
    </group>
  )
}

useGLTF.preload('/models/HeadNoEyes.glb')
