import * as React from 'react'
import s from '../../styles/scenes.module.scss'

const SayHiPass: React.FC = (props) => {
  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        scrollSnapAlign: 'start',
      }}
    >
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
        }}
      >
        <div
          style={{
            zIndex: 2,
            pointerEvents: 'auto',
            textAlign: 'center',
          }}
        >
          <h1>Say Hi 👋</h1>
          <p>
            <a
              style={{
                color: '#eeeda8',
              }}
              href="mailto:dylanwinter@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              ✉️ Shoot me an email
            </a>
          </p>{' '}
          <p>
            <a
              style={{
                color: '#eeeda8',
              }}
              href="https://www.linkedin.com/in/dylan-winter-b286ba11/"
              target="_blank"
              rel="noopener noreferrer"
            >
              🪪 Find me on LinkedIn
            </a>
          </p>
          <p>
            <a
              style={{
                color: '#eeeda8',
              }}
              href={process.env.PUBLIC_URL + '/DylanWinter_Resume.pdf'}
              target="_blank"
              rel="noopener noreferrer"
            >
              📰 Grab my resume
            </a>
          </p>
          <p>
            <a
              style={{
                color: '#eeeda8',
              }}
              href={process.env.PUBLIC_URL + '/SelectedProjects.pdf'}
              target="_blank"
              rel="noopener noreferrer"
            >
              👀 See project examples
            </a>
          </p>
          <p>
            <a
              style={{
                color: '#eeeda8',
              }}
              href={'https://www.youtube.com/watch?v=WLoPZ8ukCQg'}
              target="_blank"
              rel="noopener noreferrer"
            >
              ▶️ Check my motion reel
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default SayHiPass
