import Home from './pages/Home'
import './App.scss'

function App() {

  // const aspect = useAspect()

  return (
    <div className={'App'}>
      <Home />
    </div>
  )
}

export default App
