/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useMemo, forwardRef } from 'react'
import * as THREE from 'three'

import { useGLTF } from '@react-three/drei'

import { useTexture, useCubeTexture } from '@react-three/drei'

export default forwardRef(function (props, ref) {
  const { nodes } = useGLTF('/models/Skull.glb')
  // const matCapTex = useTexture('/textures/mc_gold.png')
  // const matCap = useMemo(()=> {
  //   return new THREE.MeshMatcapMaterial({matcap: matCapTex})
  // }, [matCapTex])

  const envMap = useCubeTexture(
    ['px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png'],
    {
      path: '/environments/studio/',
    },
  )

  const goldMat = useMemo(
    () =>
      new THREE.MeshStandardMaterial({
        envMap: envMap,
        envMapIntensity: 3,
        emissive: 1,
        roughness: 0.03,
        metalness: 1,
        color: '#53521c',
      }),
    [],
  )

  return (
    <group
      ref={ref}
      {...props}
      dispose={null}
      rotateY={Math.PI}
      scale={1.2}
      position-y={0.7}
    >
      <mesh
        material={goldMat}
        geometry={nodes.Skull.geometry}
        position={[0.1, -1.49, -0.69]}
        rotation={[Math.PI / 2, 0, 0]}
      >
        <mesh material={goldMat} geometry={nodes.jaw.geometry} />
      </mesh>
    </group>
  )
})

useGLTF.preload('/models/Skull.glb')
