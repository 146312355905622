import * as React from 'react'
import {
  useRef,
  useState,
  useEffect,
  useContext,
  MutableRefObject,
} from 'react'

import { IDContext } from '../components/Section/Section'
import { getSectionID } from "../data/getSectionID"
import { useStore } from '../data/state'

function useRelativeSection(offset: number) {
  const curSection = useContext(IDContext)
  const targetSectionIndex = curSection.index + offset
  const targetSectionID = getSectionID(targetSectionIndex)

  const section = useStore(state=>state.domElements.get(targetSectionID))

  return section
}

export { useRelativeSection as useRelativeSection }
export default useRelativeSection
