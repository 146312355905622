import * as React from 'react'
import * as THREE from 'three'
import { useRef, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Canvas } from '@react-three/fiber'
import { Suspense } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { PerspectiveCamera, Html, useProgress, Loader } from '@react-three/drei'
import { DOMSection, THREESection } from '../Section/Section'
import ScrollSnap from 'scroll-snap'
import { EffectComposer, Bloom } from '@react-three/postprocessing'

// State
import { useStore, getSectionID } from '../../data/state'
import { createPortal } from 'react-dom'
import { ShadowMapType } from 'three'
import Particles from '../../vfx/Particles'
import useMousePosition from '../../hooks/useMousePosition'

// Register the scrolltrigger plugin once, on file load. Do not delete this!
gsap.registerPlugin(ScrollTrigger)

const CanvasContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`

export const AllSectionsContext = React.createContext<HTMLDivElement[]>([])

export const PageProgressContext = React.createContext<number>(0)

const fogColor = new THREE.Color('#000000')

const ScrollContainer: React.FC = (props) => {
  // Force the experience to start from the top


  const mouseRef = useRef([0, 0])
  const onMouseMove = useCallback(
    ({ clientX: x, clientY: y }) =>
      (mouseRef.current = [
        (x / window.innerWidth - 0.5) * 2,
        (y / window.innerHeight) * 0.5 * 2,
      ]),
    [],
  )

  React.useEffect(() => {
    window.addEventListener('mousemove', onMouseMove)
    return () => {
      window.removeEventListener('mousemove', onMouseMove)
    }
  }, [])

  const sceneDefs = useStore((state) => state.scenes)

  const mainContainerRef = React.useRef<HTMLDivElement | null>(null)

  const snapCB = () => {
    // console.log('Snapped')
  }

  useEffect(() => {

    const snapConfig = {
      snapDestinationY: '90%',
      timeout: 100,
      duration: 300,
      threshold: 0.5,
      snapStop: false,
    }
  

    if (mainContainerRef.current )  {
      const snap = new ScrollSnap(mainContainerRef.current, snapConfig)
      //@ts-ignore
      snap.bind(snapCB) 
    } 
  }, [])

  return (
    <React.Fragment>
      <div style={{
        position: 'relative'
      }} 
      ref={mainContainerRef}
      >
        {sceneDefs.map((node, index) => {
          return (
            <DOMSection
              key={index}
              index={index}
              color={node.bgColor}
              // length={node.viewLength}
            >
              {node.html && node.html}
            </DOMSection>
          )
        })}
      </div>

      <CanvasContainer>
        <Canvas
          onMouseMove={onMouseMove}
          shadows
          gl={{
            alpha: true,
          }}
        >
          <PerspectiveCamera makeDefault position={[0, 0, 20]} />
          <Suspense
            fallback={
              <Html fullscreen={true}>
                <div
                  style={{
                    margin: '0',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                    <Loader/>
                </div>
              </Html>
            }
          >
          {/* @ts-ignore */}
            {sceneDefs.map(({ three }, index) => {
              return (
                <THREESection index={index} key={index}>
                  {three && three}
                </THREESection>
              )
            })}
            <Particles count={10000} mouse={mouseRef} />
            {/* <Sparks count={20} mouse={mouseRef} colors={['#A2CCB6', '#FCEEB5', '#EE786E', '#e0feff', 'lightpink', 'lightblue']} /> */}
          </Suspense>
          <fog attach={'fog'} color={fogColor} near={20} far={200}></fog>
        </Canvas>
      </CanvasContainer>
    </React.Fragment>
  )
}

export default ScrollContainer
